import styled from "styled-components"

const VideoPlayer = styled.video`
  object-fit: cover;
  width: 100%;
  overflow: hidden;
  z-index: -2;
`

export default VideoPlayer
