import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { BackgroundImageContainer } from "../course/Components"
import Metatags from "../components/base/Metatags"
import Markdown from "../components/base/markdown/Markdown"
import { FluidContainer } from "../components/base/Container"
import { Title, Subtitle } from "../components/base/Text"
import { getDateRange } from "../components/utils"
import VideoPlayer from "../components/home/VideoPlayer"

const BackgroundContainer = styled.div`
  width: 100%;
  background: linear-gradient(
    to bottom,
    white,
    ${props => props.theme.colors.grey200}
  );
`

const Container = styled.div`
  display: flex;
  justify-items: center;
`

const InternalContainer = styled.div`
  width: 100%;
  max-width: ${props => props.theme.containers.md};
  margin-top: ${props => props.theme.margins.xl};
`

const VideoContainer = styled.div`
  padding: ${props => props.theme.margins.xxl} 0;
`
const PastCourseTemplate = ({ data }) => {
  const {
    title,
    slug,
    courseBackground,
    location: { city, country },
    startDate,
    endDate,
    postMortem: { postMortem },
    videoUrl,
    metaTitle,
    metaDescription,
  } = data.contentfulCourse

  return (
    <Layout>
      <Metatags
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        slug={slug}
      />
      <BackgroundImageContainer>
        <Img
          fluid={courseBackground.fluid}
          title={courseBackground.description}
          critical
        />
      </BackgroundImageContainer>

      <BackgroundContainer>
        <FluidContainer>
          <Container>
            <InternalContainer>
              <Title size="xl" color="primary500" margin="nm" black>
                {title}
              </Title>
              <Subtitle
                size="nm"
                color="primary900"
                margin="xl"
              >{`${getDateRange({
                startDate,
                endDate,
              })}, ${city}, ${country}`}</Subtitle>
              <Markdown text={postMortem} />
              {videoUrl && (
                <VideoContainer>
                  <VideoPlayer
                    autoPlay
                    loop
                    muted
                    playsInline
                    preLoad={true}
                    controls
                  >
                    <source src={videoUrl} type="video/mp4" />
                  </VideoPlayer>
                </VideoContainer>
              )}
            </InternalContainer>
          </Container>
        </FluidContainer>
      </BackgroundContainer>
    </Layout>
  )
}

export default PastCourseTemplate

export const query = graphql`
  query($slug: String!) {
    contentfulCourse(slug: { eq: $slug }) {
      title
      slug
      startDate
      endDate
      isPastCourse
      videoUrl
      color
      postMortem {
        postMortem
      }

      courseBackground {
        title
        description
        fluid(maxWidth: 1440) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      location {
        city
        country
      }
    }
  }
`
